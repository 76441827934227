<template>
  <transition name="fade">
    <div class="vue-splash vue-splash--fixed">
      <b-row
        align-v="center"
        style="margin: 10px"
      >
        <b-col
          cols="4"
          class="pr-5"
        >
          <div class="vue-splash__anim">
            <img
              :src="logo"
              style="width: 180px"
            >
          </div>
        </b-col>
        <b-col cols="8">
          <div class="vue-splash__text">
            <span>{{ $t('Carregando applicação') }}</span>
          </div>
          <div class="vue-splash__step-list">
            <b-list-group>
              <b-list-group-item
                v-for="s in steps"
                :key="s.name"
                class="flex-column align-items-start"
                :variant="s.status === 'ERROR' ? 'danger' : ''"
              >
                <div class="d-flex justify-content-between">
                  <span :class="{ 'font-weight-bolder': s.status === 'IN_PROGRESS' }">
                    {{ $t(`${s.description}`) }}
                  </span>
                  <p class="pl-2 h4">
                    <b-spinner
                      v-if="s.status === 'IN_PROGRESS'"
                      small
                      style="margin-bottom: 1px"
                      size="20"
                    />
                    <b-icon
                      v-if="s.status !== 'IN_PROGRESS'"
                      :color="s.status === 'DONE' ? 'green' : ''"
                      :icon="statusIcon(s)"
                    />
                  </p>
                </div>

                <p
                  v-if="s.message"
                  class="mb-1"
                >
                  {{ $t(`${s.message}`) }}
                </p>
              </b-list-group-item>
              <b-list-group-item
                v-if="alertError.show"
                variant="danger"
              >
                <div class="d-flex justify-content-between">
                  <h5 class="mb-1">
                    {{ $t(`${alertError.message}`) }}
                  </h5>
                </div>

                <p class="mb-1">
                  {{ $t(`${alertError.closeMessage}`) }}
                </p>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-col>
      </b-row>
    </div>
  </transition>
</template>

<script>
import { BListGroup, BListGroupItem, BIcon, BSpinner, BRow, BCol } from 'bootstrap-vue'
import logo from '@/assets/images/logo/echope_login.png'
import isElectron from 'is-electron'

export default {
  name: 'ElectronSplashScreen',
  components: {
    BListGroup,
    BListGroupItem,
    BIcon,
    BSpinner,
    BRow,
    BCol,
  },

  data() {
    return {
      logo,
      splashColor: '#00bfa5',
      validStatus: ['DONE', 'IN_PROGRESS', 'PENDING', 'ERROR', 'SKIPPED'],
      alertError: {
        show: false,
        message: this.$t(
          'Verifique os erros durante a inicialização do sistema, caso necessário por favor entre em contato com o suporte.'
        ),
        closeMessage: this.$t('Fechando em X segundos'),
      },
      steps: [
        {
          name: 'health_check_internet_connection',
          description: this.$t('Verificando conexão com a internet'),
          status: 'PENDING',
        },
        {
          name: 'check_app_versions',
          description: this.$t('Verificando atualizações'),
          status: 'PENDING',
        },
        {
          name: 'update_agent_auto_update',
          description: this.$t('Atualização agent auto update'),
          status: 'PENDING',
        },
        {
          name: 'update_agent',
          description: this.$t('Atualização agent'),
          status: 'PENDING',
        },
        {
          name: 'update_electron_app',
          description: this.$t('Atualização admin app'),
          status: 'PENDING',
        },
        {
          name: 'services_start_agent',
          description: this.$t('Iniciando serviço agent'),
          status: 'PENDING',
        },
        {
          name: 'services_start_tef_client',
          description: this.$t('Iniciando serviço TEF'),
          status: 'PENDING',
        },
        {
          name: 'health_check_agent',
          description: this.$t('Verificando serviço agent'),
          status: 'PENDING',
        },
      ],
    }
  },
  computed: {
    imageStyle() {
      const width = `${this.size}px`

      return {
        width,
      }
    },
  },
  created() {
    this.setColor()
  },
  mounted() {
    if (isElectron()) {
      window.electronAPP.splashSreen.stepMessage(async messages => {
        // eslint-disable-next-line no-restricted-syntax
        for (const m of messages) {
          if (m.name === 'has_errors_delay') {
            this.showAlertCloseMessage()
            return
          }

          const { steps } = this
          const index = steps.findIndex(s => s.name === m.name)
          if (index < 0) {
            throw Error(`step with name: '${m.name}' not found ... received payload: ${m}`)
          }

          if (!this.validStatus.includes(m.status)) {
            throw Error(`status : '${m.status}' not found ... valides are: ${this.validStatus}`)
          }

          this.steps = this.steps.map(s => {
            if (s.name === m.name) {
              return {
                ...s,
                ...m,
              }
            }
            return s
          })
        }
      })
    }
  },
  methods: {
    async showAlertCloseMessage() {
      this.alertError.show = true
      let secondsInterval
      let seconds = 6
      const incrementSeconds = () => {
        seconds -= 1
        this.alertError.closeMessage = this.$t(`Fechando em ${seconds} segundos.`)

        if (seconds === 0) {
          clearInterval(secondsInterval)
        }
      }

      secondsInterval = setInterval(incrementSeconds, 1000)

      await new Promise(resolve => setTimeout(resolve, seconds * 1000))
    },
    setColor() {
      document.documentElement.style.setProperty('--splash-color', this.splashColor)
    },
    statusIcon(step) {
      if (step.status === 'DONE') return 'check-lg'
      if (step.status === 'SKIPPED') return 'dash-lg'
      if (step.status === 'ERROR') return 'x-lg'
      if (step.status === 'WARNING') return 'exclamation-triangle'
      return 'stop-fill'
    },
  },
}
</script>

<style lang="scss" scoped>
$splash-color: var(--splash-color);

.vue-splash {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: '#ffffff';

  &--fixed {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: white;
  }
}

.vue-splash__anim {
  text-align: center;
  animation: splashAnimation 1.3s infinite;

  img {
    margin: auto;
  }
}

.vue-splash__text {
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
}

.vue-splash__step-list {
  margin-top: 10px;
  text-align: center;
}

@keyframes splashAnimation {
  0% {
    filter: drop-shadow(0px 0px 0px $splash-color);
  }
  100% {
    filter: drop-shadow(0px 0px 200px $splash-color);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
