var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      { staticClass: "vue-splash vue-splash--fixed" },
      [
        _c(
          "b-row",
          { staticStyle: { margin: "10px" }, attrs: { "align-v": "center" } },
          [
            _c("b-col", { staticClass: "pr-5", attrs: { cols: "4" } }, [
              _c("div", { staticClass: "vue-splash__anim" }, [
                _c("img", {
                  staticStyle: { width: "180px" },
                  attrs: { src: _vm.logo },
                }),
              ]),
            ]),
            _c("b-col", { attrs: { cols: "8" } }, [
              _c("div", { staticClass: "vue-splash__text" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("Carregando applicação")))]),
              ]),
              _c(
                "div",
                { staticClass: "vue-splash__step-list" },
                [
                  _c(
                    "b-list-group",
                    [
                      _vm._l(_vm.steps, function (s) {
                        return _c(
                          "b-list-group-item",
                          {
                            key: s.name,
                            staticClass: "flex-column align-items-start",
                            attrs: {
                              variant: s.status === "ERROR" ? "danger" : "",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-between" },
                              [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "font-weight-bolder":
                                        s.status === "IN_PROGRESS",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("" + s.description)) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "pl-2 h4" },
                                  [
                                    s.status === "IN_PROGRESS"
                                      ? _c("b-spinner", {
                                          staticStyle: {
                                            "margin-bottom": "1px",
                                          },
                                          attrs: { small: "", size: "20" },
                                        })
                                      : _vm._e(),
                                    s.status !== "IN_PROGRESS"
                                      ? _c("b-icon", {
                                          attrs: {
                                            color:
                                              s.status === "DONE"
                                                ? "green"
                                                : "",
                                            icon: _vm.statusIcon(s),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            s.message
                              ? _c("p", { staticClass: "mb-1" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("" + s.message)) + " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                      _vm.alertError.show
                        ? _c(
                            "b-list-group-item",
                            { attrs: { variant: "danger" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-between",
                                },
                                [
                                  _c("h5", { staticClass: "mb-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("" + _vm.alertError.message)
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("" + _vm.alertError.closeMessage)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }